'use client';

import { Closables } from '@ocodelib/ex-util/rx';
import { LightboxOpenEvent } from './LightboxOpenEvent';
import 'photoswipe/style.css';
import { useCallback, useEffect, useMemo, useState } from 'react';

export function LightboxOpenEventHandler() {
  const [opened, setOpened] = useState(false);

  // lightbox가 open된 상태에서만 존재하는 closable
  const openedClosables = useMemo(() => new Closables(), []);

  const onLightboxOpened = useCallback(() => {
    setOpened(true);
    LightboxOpenEvent.emit(true);
  }, []);

  const onLightboxDestroy = useCallback(() => {
    setOpened(false);
    LightboxOpenEvent.emit(false);
    openedClosables.close();
  }, [openedClosables]);

  const openImageViewer = useCallback(
    (imgs: HTMLImageElement[], firstIndex: number) => {
      // const imageUrl = img.dataset["lightboxUrl"] ?? img.src;
      openedClosables.close(); //
      createLightbox(imgs).then((lightbox) => {
        lightbox.on('beforeOpen', onLightboxOpened);
        lightbox.on('destroy', onLightboxDestroy);
        openedClosables.add(() => {
          lightbox.off('beforeOpen', onLightboxOpened);
          lightbox.off('destroy', onLightboxDestroy);
          lightbox.destroy();
        });
        lightbox.loadAndOpen(firstIndex < 0 ? 0 : firstIndex);
      });
    },
    [onLightboxOpened, onLightboxDestroy],
  );

  useEffect(() => {
    const closable = new Closables();
    closable.addDocumentListener('click', (event: MouseEvent) => {
      if (!event.target) return;
      const { target } = event;
      if ((target as any)['tagName'] !== 'IMG') return;
      if (event.defaultPrevented) return;
      const img = target as HTMLImageElement;
      const parent = img.closest('.lightbox-parent');
      if (parent) {
        const imgs = Array.prototype.slice.call(
          parent.querySelectorAll('img'),
        ) as HTMLImageElement[];
        if (imgs.length > 0) {
          openImageViewer(imgs, imgs.indexOf(img));
        } else {
          console.warn('이미지가 왜 없나');
        }
      } else {
        if (!img.matches('.lightbox')) return;
        openImageViewer([img], 0);
      }
    });
    return () => {
      closable.close();
    };
  }, [openImageViewer]);

  return null;
}

async function createLightbox(imgs: HTMLImageElement[]) {
  const PhotoSwipeLightbox = await import('photoswipe/lightbox').then((m) => m.default);
  const lightbox = new PhotoSwipeLightbox({
    gallery: '#SAMPLE',
    children: 'a',
    pswpModule: () => import('photoswipe'),
  });
  lightbox.addFilter('numItems', () => imgs.length);
  lightbox.addFilter('itemData', (itemData, index) => {
    const img = imgs[index]!;
    return {
      src: img.src,
      width: img.naturalWidth || img.width,
      height: img.naturalHeight || img.height,
    };
  });
  lightbox.on('beforeOpen', () => {
    console.log('lightbox beforeOpen ');
  });
  lightbox.on('close', () => {
    // PhotoSwipe starts to close, unbind most events here
    console.log('lightbox close');
  });
  lightbox.on('destroy', () => {
    // PhotoSwipe is fully closed, destroy everything
    console.log('lightbox destroy');
  });
  lightbox.init();
  return lightbox;
}
