import type { SxProps } from '@mui/material';
import { Avatar, Box, ButtonBase } from '@mui/material';
import type { UserAccount } from '@ocode/domain';
import { flatSx } from '@ocodelib/ui-common';
import { DEFAULT_PROFILE_IMAGE_URL } from '../../config/code-config';
import { useLoginUser } from '../../store/useAuthStore';
import { maskUserId } from '../../util/mask-user-id';

interface Props {
  sx?: SxProps;
  onClick?: React.MouseEventHandler;
  profile: UserAccount;
}

export function ProfileBox(props: Props) {
  const { sx, onClick, profile } = props;
  const loginUser = useLoginUser();
  const isAdmin = loginUser.isAdmin;
  const { nickName, loginId, profileImageUrl } = profile;

  const avatarUrl = profileImageUrl ?? DEFAULT_PROFILE_IMAGE_URL;

  return (
    <Box
      className="ProfileBox-root"
      sx={flatSx(
        {
          display: 'flex',
          alignItems: 'center',
        },
        sx,
      )}
    >
      {onClick ? (
        <>
          <ButtonBase component="div" onClick={onClick} style={{ borderRadius: '50%' }}>
            <Avatar
              aria-label="user profile"
              src={avatarUrl}
              sx={{
                fontSize: '1.0rem',
                width: 32,
                height: 32,
                border: '1px solid #eee',
              }}
            />
          </ButtonBase>
          <ButtonBase
            component="div"
            sx={{
              ml: 1,
              whiteSpace: 'nowrap',
              maxWidth: 100,
              overflow: 'hidden',
              letterSpacing: -0.5,
              fontFamily: 'inherit',
              color: 'inherit',
            }}
            onClick={onClick}
          >
            {nickName || maskUserId(loginId, isAdmin)}
          </ButtonBase>
        </>
      ) : (
        <>
          <Avatar
            aria-label="user profile"
            src={avatarUrl}
            sx={{
              fontSize: '1.0rem',
              width: 32,
              height: 32,
            }}
          />
          <Box
            sx={{
              ml: 1,
              whiteSpace: 'nowrap',
              maxWidth: 100,
              overflow: 'hidden',
              letterSpacing: -0.5,
              fontFamily: 'inherit',
            }}
            onClick={onClick}
          >
            {nickName || maskUserId(loginId, isAdmin)}
          </Box>
        </>
      )}
    </Box>
  );
}
