import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { Ignore } from '@ocodelib/ui-common';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { LoginTokenStorage } from '../../auth/authentication';
import { LoginDialog, type LoginDialogProps } from '../../dialogs/LoginDialog';
import { routerPush } from '../../router/router-utils';
import { useLoginUser } from '../../store/useAuthStore';
import { ProfileBox } from './ProfileBox';

interface DialogParams {
  id: 'LoginDialog';
  props: LoginDialogProps;
}

interface Props {
  hideJoinButton?: boolean;
  whiteText?: boolean;
}

export function LoginButton(props: Props) {
  const { hideJoinButton = false, whiteText = false } = props;
  const loginUser = useLoginUser();
  const profile = loginUser.profile;
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpened = Boolean(menuAnchorEl);
  const [dialogParams, setDialogParams] = useState<DialogParams>();

  const openLoginDialog = () => {
    setDialogParams({
      id: 'LoginDialog',
      props: {
        open: true,
        onClose: () => {
          setDialogParams(undefined);
        },
      },
    });
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const handleClickLoginBtn = () => {
    // LoginDialogOpenEvent.emit(Date.now());
    routerPush('/login');
  };

  const handleClickLogoutBtn = () => {
    LoginTokenStorage.clear();
    setTimeout(() => {
      toast.success('로그아웃 되었습니다', {
        autoClose: 1500,
        position: 'top-center',
      });
    }, 500);
  };

  const handleClickUserId = (event: React.MouseEvent) => {
    // routerPush('/mypage/activities')
    setMenuAnchorEl(event.target as HTMLElement);
  };

  const handleClickLogoutMenu = () => {
    setMenuAnchorEl(null);
    handleClickLogoutBtn();
  };

  const handleClickAccountMenu = () => {
    setMenuAnchorEl(null);
    // handleClickLogoutBtn();
    // goto my page
    routerPush('/my-page');
  };

  return (
    <Box
      className="LoginButton-root"
      sx={{
        color: 'primary.contrastText',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexShrink: 0,
        mr: 1,
        '& .MuiDivider-root': {
          height: '12px',
          alignSelf: 'center',
          ml: 0,
        },
      }}
    >
      {!profile && (
        <>
          <ButtonBase
            sx={{
              py: 0.5,
              px: {
                xs: 0.5,
                sm: 1,
              },
              borderRadius: 1,
              color: whiteText ? '#fff' : '#777',
              whiteSpace: 'nowrap',
            }}
            // onClick={handleClickLoginBtn}
            onClick={openLoginDialog}
          >
            <Typography component="span" mr={1}>
              로그인
            </Typography>
            <AccountCircleOutlinedIcon />
          </ButtonBase>

          <Ignore>
            {!hideJoinButton && <Divider orientation="vertical" flexItem sx={{ m: '0 4px' }} />}
            {!hideJoinButton && (
              <Button
                size="small"
                color="inherit"
                onClick={() => {
                  routerPush('/join');
                }}
                sx={{ whiteSpace: 'nowrap' }}
              >
                회원가입
              </Button>
            )}
          </Ignore>
        </>
      )}
      {profile && (
        <>
          <ProfileBox
            profile={profile}
            onClick={handleClickUserId}
            sx={{
              mr: 1,
              color: whiteText ? '#fff' : '#222',
            }}
          />
          <Menu
            anchorEl={menuAnchorEl}
            open={menuOpened}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClickAccountMenu}>
              <ListItemIcon>
                <AccountCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>My account</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClickLogoutMenu}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>로그아웃</ListItemText>
            </MenuItem>
          </Menu>
          <Ignore>
            <Divider orientation="vertical" flexItem style={{ margin: '0 8px' }} />
            <Button
              onClick={handleClickLogoutBtn}
              variant="text"
              size="small"
              color="inherit"
              sx={{ whiteSpace: 'nowrap', textTransform: 'none' }}
            >
              로그아웃
            </Button>
          </Ignore>
        </>
      )}
      {dialogParams?.id === 'LoginDialog' && <LoginDialog {...dialogParams.props} />}
    </Box>
  );
}
