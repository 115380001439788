import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { type CoppiaPub } from '@ocode/domain';
import { isEnterKeyEvent, isEnterOrTabKeyEvent, requestFocusSelector } from '@ocodelib/ui-common';
import { isBlank, isNotBlank, trim } from '@ocodelib/util/string';
import { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { LoginProfileLoadedEvent } from '../../../auth/authentication';
import { PasswordTextField } from '../../../components/PasswordTextField';
import { HakgyoansimKkwabaegiOTFR } from '../../../font';
import { useCoppiaPub } from '../../../provider/CoppiaPubProvider';
import { useApi } from '../../../provider/useApi';
import { handleError } from '../../../util/handle-error';

interface InputData {
  loginId: string;
  pw: string;
}

interface Props {
  onClose: React.MouseEventHandler;
  onLoginSuccess: () => void;
}

export function LoginForm(props: Props) {
  const { onClose, onLoginSuccess } = props;

  const rootRef = useRef<HTMLElement>();
  const api = useApi();
  const coppia = useCoppiaPub();
  const [inputData, setInputData] = useState<Partial<InputData>>({});
  const [loading, setLoading] = useState(false);
  const [loginProcessing, setLoginProcessing] = useState(false);
  const [idSaveChecked, setIdSaveChecked] = useState(false);

  const updateInput = (part: Partial<InputData>) => {
    setInputData((prev) => ({ ...prev, ...part }));
  };

  const focus = useCallback((selector: string) => {
    requestFocusSelector(rootRef.current, selector, 0);
  }, []);

  /**
   * 서버 로그인 실행
   */
  const doLogin = useCallback(
    async (loginId: string, password: string, coppia: CoppiaPub): Promise<boolean> => {
      setLoading(true);
      try {
        // const publicKey = await CoppiaUtil.loadPublicKey(coppia.pubKey);
        // 임시로 비번 암호안함
        // const pw = await CoppiaUtil.encryptText(publicKey, password);
        // const cid = coppia.cid,
        const { profile: userProfile } = await api.auth.signIn({
          cid: 0,
          loginId,
          pw: password,
        });
        LoginProfileLoadedEvent.emit({ ...userProfile, __loadedTimestamp: Date.now() });
        toast.success('로그인 되었습니다', {
          autoClose: 1500,
          position: 'top-center',
        });
        return true;
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
      return false;
    },
    [api],
  );

  const validateAndLogin = () => {
    // if (!coppia) {
    //   console.warn("coppia is null");
    //   return;
    // }

    setLoginProcessing(true);
    const loginId = trim(inputData.loginId);
    const pw = trim(inputData.pw);
    if (isBlank(loginId)) {
      toast.warn('사용자 ID를 입력해주세요');
      setLoginProcessing(false);
      return false;
    }

    if (isBlank(pw)) {
      toast.warn('비밀번호를 입력해주세요');
      setLoginProcessing(false);
      return;
    }

    doLogin(loginId, pw, coppia as any)
      .then((succ) => {
        if (succ) {
          onLoginSuccess();
        }
      })
      .finally(() => {
        setLoginProcessing(false);
      });
  };

  // 로그인 버튼 클릭
  const handleClickLogin = () => {
    if (loginProcessing) return;
    validateAndLogin();
  };

  // 비밀번호 찾기 버튼 클릭
  const handleClickPwFind = () => {
    //
  };

  // 가입 버튼 클릭
  const handleClickJoin = () => {
    //
  };

  const { loginId, pw } = inputData;
  return (
    <>
      <DialogContent sx={{ p: 5 }} ref={rootRef}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            minHeight: 52,
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            pl: {
              xs: 1,
              md: 2,
            },
            pr: 1,
          }}
        >
          {/* <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton> */}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 5,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: 500,
              color: '#00acff',
              fontFamily: HakgyoansimKkwabaegiOTFR.style,
            }}
          >
            Login
          </Typography>
        </Box>
        <Box p={2}>
          <Box>
            <TextField
              fullWidth
              label="아이디"
              variant="outlined"
              autoFocus
              disabled={loading || loginProcessing}
              size="medium"
              onChange={(e) => {
                updateInput({ loginId: e.target.value });
              }}
              value={loginId ?? ''}
              className="LoginForm-loginInput LoginForm-loginId"
              onKeyDown={(e) => {
                if (isNotBlank(loginId) && isEnterOrTabKeyEvent(e)) {
                  e.preventDefault();
                  focus('.LoginForm-pw input');
                }
              }}
            />
          </Box>
          <Box mt={2}>
            <PasswordTextField
              className="LoginForm-loginInput LoginForm-pw"
              value={pw ?? ''}
              onChange={(e) => {
                updateInput({ pw: e.target.value });
              }}
              onKeyDown={(e) => {
                if (isNotBlank(pw) && isEnterKeyEvent(e)) {
                  e.preventDefault();
                  focus('.LoginForm-loginBtn');
                  handleClickLogin();
                }
              }}
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={idSaveChecked}
                onChange={(e) => {
                  setIdSaveChecked(e.target.checked);
                }}
                color="primary"
              />
            }
            label="아이디 저장"
          />
          <Box display="flex" alignItems="center" flexDirection="column" mt={1}>
            <Button
              className="LoginForm-loginBtn"
              variant="contained"
              size="large"
              color="primary"
              disabled={loading || loginProcessing}
              onClick={handleClickLogin}
            >
              로그인
            </Button>
            <Box
              sx={{
                mt: 1,
                color: '#363636',
                display: 'flex',
                alignItems: 'center',
                flexShrink: 0,
                '& .MuiDivider-root': {
                  height: 12,
                  alignSelf: 'center',
                  my: 0,
                  mx: 1,
                },
              }}
            >
              <Button disabled={loading} onClick={handleClickPwFind} style={{ opacity: 0.7 }}>
                비밀번호 찾기
              </Button>
              <Divider orientation="vertical" flexItem />
              <Button disabled={loading} onClick={handleClickJoin} style={{ opacity: 0.7 }}>
                회원가입
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>닫기</Button>
      </DialogActions>
    </>
  );
}
